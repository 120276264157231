import React, { Component } from "react";
import "semantic-ui-css/semantic.css";
import MainLayout from "../react-lib/apps/common/MainLayout";
import "react-table-6/react-table.css";
import { toast } from "react-toastify";

import { withRouter, Switch, Route } from "react-router-dom";
import { Button, Menu, Dropdown, Grid, Icon } from "semantic-ui-react";

// Manager
import DPIManager from "../react-lib/apis/manager/DPIManager";
import MixInManager from "../react-lib/apis/manager/MixInManager";
import MSGManager from "../react-lib/apis/manager/MSGManager";
import PRXManager from "../react-lib/apis/manager/PRXManager";
import PTMManager from "../react-lib/apis/manager/PTMManager";
import REGManager from "../react-lib/apis/manager/REGManager";
import CoreManager from "../react-lib/apis/manager/CoreManager";
import UserManager from "../react-lib/apis/manager/UserManager";
import QUEManager from "../react-lib/apis/manager/QUEManager";
import HRMManager from "../react-lib/apis/manager/HRMManager";
import LineLoginManager from "../react-lib/apps/IsHealth/Common/LineLoginManager";
import CardDiagFormHistoryController from "../react-lib/apps/IsHealth/Nurse/CardDiagFormHistoryController";
import DashboardController from "../react-lib/apps/IsHealth/Dashboard/DashboardController";
// import DiagComposerController from "../react-lib/apps/IsHealth/Studio/DiagComposerController";
import CardVitalSignController from "../react-lib/apps/PHV/CardVitalSignController";
// Controller
import {
  CardClassifyController,
  CardNurseNoteController,
  CardPatientPanelController,
  CardProgressNoteController,
} from "../react-lib/apps/IsHealth/Nurse";
import { ChatController } from "../react-lib/apps/IsHealth/Chat";
import QueueController from "../react-lib/apps/QUE/QueueController";
import AppController from "../react-lib/frameworks/AppController";

import * as IsHealthCommon from "../react-lib/apps/IsHealth/Common";
import { CookiesProvider, withCookies } from "react-cookie";
import * as serviceWorker from "../serviceWorker";
import DoctorLogin from "./DoctorLogin";
import MainScreen from "./MainScreen";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
import EmailLogin from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailLogin";
import EmailRegister from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailRegister";
import EmailCallback from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailCallback";
import AppointmentDashboardController from "../react-lib/apps/IsHealth/APP/AppointmentDashboardController";
import "../css/main.scss";
import CONFIG from "../config/config";
import CertLogin from "../react-lib/apps/IsHealth/Common/CertLogin/CertLogin";

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

const URL_PREFIX = {
  PENGUIN: "penguin",
};
class MainDoctor extends Component {
  constructor(props) {
    super(props);

    this.mainScreen = React.createRef();
    this.controller = new AppController(
      () => {
        return this.state;
      },
      (state) => {
        this.setState(state);
      },
      window
    );

    const { cookies } = props;

    this.state = {
      division: cookies.get("division_id"),
      apiToken: cookies.get("apiToken"),
      userId: cookies.get("userId"),
      fullname: cookies.get("fullname"),
      username: cookies.get("username"),
      providerId: cookies.get("providerId"),
      providerInfo: {},
      divisionName: "",
      finishedTriageLevel: [],
      myDivisions: [],
      currentDivision: null,
      landingLoading: false,
    };

    this.dpiManager = new DPIManager();
    this.mixInManager = new MixInManager();
    this.msgManager = new MSGManager();
    this.ptmManager = new PTMManager();
    this.regManager = new REGManager();
    this.prxManager = new PRXManager();
    this.coreManager = new CoreManager();
    this.userManager = new UserManager();
    this.queManager = new QUEManager();
    this.hrmManager = new HRMManager();
    this.lineLoginManager = new LineLoginManager();

    this.queueController = new QueueController({
      queManager: this.queManager,
      prxManager: this.prxManager,
      hrmManager: this.hrmManager,
    });

    this.classifyController = new CardClassifyController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
      userManager: this.userManager,
    });

    this.nurseNoteController = new CardNurseNoteController({
      prxManager: this.prxManager,
      ptmManager: this.ptmManager,
    });

    this.patientPanelController = new CardPatientPanelController({
      regManager: this.regManager,
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });

    this.progressNoteController = new CardProgressNoteController({
      dpiManager: this.dpiManager,
      prxManager: this.prxManager,
    });

    this.ChatController = new ChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.diagFormController = new CardDiagFormHistoryController({
      prxManager: this.prxManager,
    });

    this.loginController = new IsHealthCommon.LoginController({
      mixInManager: this.mixInManager,
    });
    this.dashboardController = new DashboardController({
      prxManager: this.prxManager,
      coreManager: this.coreManager,
    });
    this.appointmentDashboardController = new AppointmentDashboardController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });

    this.crdVitalSignController = new CardVitalSignController({
      ptmManager: this.ptmManager,
    });

    this.lastReadMessage = null;

    vcMessenger.onMessage((message) => {
      console.log(message);
      if (message["data_message_type"] === "MESSAGE") {
        this.mainScreen.chatTable?.chatListRef?.getChatList?.();
        if (this.mainScreen.chatTable?.chatBoxRef) {
          this.mainScreen.chatTable.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }
        if (CONFIG.CLASSIFY_CHAT || CONFIG.AIRFLOW_CHAT) {
          if (this.mainScreen.qaChatTable) {
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.receivedMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            this.mainScreen.qaChatTable.receivedMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
        }
      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return;
        }
        this.lastReadMessage = message.message_id;
        if (this.mainScreen.chatTable?.chatBoxRef) {
          this.mainScreen.chatTable.chatBoxRef.receivedMessageRead({
            chatChannelId: parseInt(message["channel_id"]),
            messageId: parseInt(message["message_id"]),
          });
        }
        if (this.mainScreen.qaChatTable) {
          if (this.mainScreen.qaChatTable.chatBoxRef) {
            this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
          this.mainScreen.qaChatTable.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }
      } else if (message["event"] === "RECLASSIFY") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.getListTriageLevel();
        }
        if (this.mainScreen.dashboard) {
          this.mainScreen.dashboard.refresh();
        } else {
          this.mainScreen.chatTable?.chatListRef?.getChatList?.({
            type: "RECLASSIFY",
          });
          this.mainScreen.chatTable.getPatientInfo();
          // this.props.history.push("/Chat/"); cause of video call close unexpected
        }
        // let filterValue = this.mainScreen.chatTable.chatListRef.getFilterValue();
        // this.mainScreen.chatTable.chatBoxRef.receivedReclassify({
        //   filterValue: filterValue,
        //   encounterId: parseInt(message["encounter"]),
        //   chatChannelId: parseInt(message["chat_channel"])
        // });
      } else if (message["event"] === "DIAG_FORM_UPDATED") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.updateDiagForm({
            patientId: parseInt(message["patient_id"]),
          });
        }
      }
    });
  }

  componentDidUpdate() {
    if (
      !this.props.apiToken &&
      !this.props.cookies.get("apiToken") &
        (this.props.location.pathname !== "/") &&
      this.props.location.pathname !== "/Login" &&
      this.props.location.pathname !== "/cert-login" &&
      this.props.location.pathname !== "/callback" &&
      !this.props.location.pathname.includes("EmailLogin") &&
      !this.props.location.pathname.includes("email-callback") &&
      !this.props.location.pathname.includes("EmailRegister")
    ) {
      this.props.history.push("/");
    }
  }

  componentDidMount = () => {
    let currentURL = window.location.href;
    if (currentURL.includes(URL_PREFIX.PENGUIN)) {
      // Theme for penguin
      this.setState({ theme: THEME.PENGUIN });
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
      this.props.cookies.set("theme", THEME.PENGUIN, { path: "/" });
    } else {
      this.setState({ theme: THEME.PENTA });
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
      this.props.cookies.set("theme", THEME.PENTA, { path: "/" });
    }
    let apiToken = this.props.cookies.get("apiToken")
      ? this.props.cookies.get("apiToken")
      : this.state.apiToken;
    let userId = this.props.cookies.get("userId")
      ? this.props.cookies.get("userId")
      : this.state.userId;
    if (apiToken) {
      this.setWebsocket({ apiToken });
    }
    if (userId) {
      this.getProviderInfo({ userId });
      this.lagacyLoginFirebase(userId);
    }
    this.handleGetFinishedTriageLevel();
  };

  handleGetFinishedTriageLevel = async () => {
    let data = { config_PRX_FINISHED_TRIAGE_LEVEL: null };
    const [res, error, network] =
      await this.classifyController.getFinishedTriageLevel({ data });
    if (res) {
      if (typeof res.config_PRX_FINISHED_TRIAGE_LEVEL === "string") {
        this.setState({
          finishedTriageLevel: res.config_PRX_FINISHED_TRIAGE_LEVEL.split(" "),
        });
      }
    }
  };

  setWebsocket = ({ apiToken } = {}) => {
    vcMessenger.connect(
      "MSG",
      {
        screens: this.props.cookies.get("division_id"),
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  loadMydivision = async () => {
    let apiToken = this.props.cookies.get("apiToken")
      ? this.props.cookies.get("apiToken")
      : this.state.apiToken;
    if (!apiToken) {
      return;
    }
    const [response, error, network] = await this.prxManager.getDivisionHasUser(
      { apiToken: apiToken }
    );
    if (response && response.items.length > 0) {
      console.log("my division", response.items);
      var myDivisions = [];
      for (let d of response.items) {
        var dupicate = false;
        for (let m of myDivisions) {
          if (m.id === d.division.id) {
            dupicate = true;
            break;
          }
        }
        if (!dupicate) {
          myDivisions.push(d.division);
        }
      }
      // Prepare data to dropdown element
      var options = [];
      for (var division of myDivisions) {
        options.push({
          ...division,
          text: division.name,
          value: division.code,
        });
      }
      this.setState({ myDivisions: options });

      // Initial division
      var targetDivision = null;
      let selectedDivision = this.props.cookies.get("division_id");
      if (selectedDivision) {
        const selected = options.find(
          (division) => division.id.toString() === selectedDivision
        );
        if (selected) {
          targetDivision = selected;
        }
      }
      // No dafault division
      if (!targetDivision && options.length > 0) {
        targetDivision = options[0];
        this.props.cookies.set("division_id", targetDivision.id, { path: "/" });
      }

      if (targetDivision) {
        this.setState({
          currentDivision: targetDivision,
          division: targetDivision.id,
        });
        vcMessenger.reconnect({
          screens: targetDivision.id,
          token: this.props.cookies.get("apiToken")
            ? this.props.cookies.get("apiToken")
            : this.state.apiToken,
        });
      } else {
        console.log("There aren't division");
        alert(
          "ผู้ใช้งานนี้ไม่มี โรงพยาบาล (Division) ของระบบ กรุณาติดต่อ ผู้ดูแล"
        );
      }
    }
  };

  handleChangeDivision = (event, data) => {
    let sValue = data.value;
    const selected = this.state.myDivisions.find(
      (division) => division.value === sValue
    );
    if (selected) {
      this.setState({
        currentDivision: selected,
        division: selected.id,
      });
      vcMessenger.reconnect({
        screens: selected.id,
        token: this.props.cookies.get("apiToken")
          ? this.props.cookies.get("apiToken")
          : this.state.apiToken,
      });
      this.props.cookies.set("division_id", selected.id, { path: "/" });
    }
  };

  getProviderInfo = async ({ userId } = {}) => {
    const [response, error] = await this.queueController.getProviderInfo({
      apiToken: this.props.cookies.get("apiToken")
        ? this.props.cookies.get("apiToken")
        : this.state.apiToken,
      userId,
    });
    if (response) {
      this.setState({ providerInfo: response, providerId: response.id });
      this.props.cookies.set("providerId", response.id, { path: "/" });
    } else {
      this.setState({ providerInfo: {} });
    }
  };

  handleLoginSuccess = async (response) => {
    if (response && response.profile && response.profile.userId) {
      this.lagacyLoginFirebase(response.profile.userId);
    }

    this.props.cookies.set("fullname", response.profile.fullname, {
      path: "/",
    });
    this.props.cookies.set("username", response.profile.username, {
      path: "/",
    });
    // this.props.cookies.set("apiToken", "30f49e92c5bead3d9293401bf9ae873f44a8a1d8", { path: "/" });
    this.props.cookies.set("apiToken", response.token, { path: "/" });
    this.props.cookies.set("userId", response.profile.userId, { path: "/" });
    this.getProviderInfo({ userId: response.profile.userId });
    this.setState({
      userId: response.profile.userId,
      apiToken: response.token,
    });
    this.setWebsocket({ apiToken: response.token });
    this.setState({ landingLoading: false });
    this.props.history.push({
      pathname: "/Chat",
    });
  };

  handleLogout = async () => {
    serviceWorker.unregister();
    if (window.FB) {
      window.FB.logout(function (response) {
        console.log(" user Logout Facebook !");
      });
    }
    console.log("unregister service worker");
    // Remove data
    this.props.cookies.remove("fullname", { path: "/" });
    this.props.cookies.remove("username", { path: "/" });
    this.props.cookies.remove("apiToken", { path: "/" });
    this.props.cookies.remove("division_id", { path: "/" });
    this.props.cookies.remove("userId", { path: "/" });
    this.setState({
      apiToken: this.props.cookies.apiToken,
      userId: this.props.cookies.userId,
    });
    this.props.history.push({ pathname: "/", state: {} });
  };

  getRightMenuItem = () => {
    if (
      this.state.apiToken ||
      this.props.cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <Menu.Item position="right">
          <h3>
            {this.state.providerInfo.employee_info
              ? this.state.providerInfo.employee_info.full_name
              : ""}
          </h3>
          <Button onClick={this.handleLogout}>Logout</Button>
        </Menu.Item>
      );
    } else if (this.props.location.pathname === "/") {
      return (
        <Menu.Item position="right">
          <Button
            onClick={(e) => {
              this.props.history.push("/Login");
            }}
          >
            Login/Register
          </Button>
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  getLeftMenuItem = () => {
    if (this.props.location.pathname === "/") {
      return null;
    } else {
      return (
        <Menu.Item>
          <h2 onClick={this.handleHomeIconClick}>
            {`IsHealth Doctor ${this.state.divisionName}`}
          </h2>
        </Menu.Item>
      );
    }
  };

  getDivisionMenuItem = () => {
    if (this.props.history.location.pathname.includes("Dashboard")) {
      return <></>;
    }
    if (
      this.state.apiToken ||
      this.props.cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <Menu.Item>
          <Dropdown
            button
            className={"icon"}
            floating
            labeled
            icon={"hospital"}
            options={this.state.myDivisions}
            search
            value={
              this.state.currentDivision ? this.state.currentDivision.value : ""
            }
            placeholder={"Select Division"}
            onChange={this.handleChangeDivision}
          />
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  /** Login with line */
  handleLineLoginSuccess = (props) => {
    if (props.pentaToken) {
      this.setState({ landingLoading: true });
      setTimeout(() => {
        this.handleLoginSuccess(props.pentaToken);
      }, 0);
    } else {
      this.setState({ landingLoading: false });
      this.props.history.replace({ pathname: "/Login" });
    }
  };

  handleLineLoginFailed = (props) => {
    this.setState({ landingLoading: false });
    console.log("handleLineLoginFailed: ", props);
    this.props.history.replace({ pathname: "/Login" });
    toast.error("Login ไม่สำเร็จ!");
  };

  componentWillUpdate(nextProps, nextState) {
    console.log(this.state.division, nextState.division);
    if (this.state.division != nextState.division) {
      this.props.history.push("/Chat/");
    }
  }

  reconnectWs = () => {
    vcMessenger.reconnect({
      screens: this.state.division,
      token: this.props.cookies.get("apiToken")
        ? this.props.cookies.get("apiToken")
        : this.state.apiToken,
    });
  };

  lagacyLoginFirebase = (userId) => {
    this.controller.functions
      .httpsCallable("createTokenFromUser")(userId.toString())
      .then((result) => {
        // console.log("Token", result.data);
        this.setState({ firebaseToken: result.data });
        this.controller.app
          .auth()
          .signInWithCustomToken(result.data)
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <CookiesProvider>
        <div>
          <Switch>
            <Route
              exact
              path="/EmailLogin"
              render={(props) => {
                return <EmailLogin {...props} />;
              }}
            />
            <Route
              exact
              path="/EmailRegister/"
              render={(props) => {
                return <EmailRegister {...props} />;
              }}
            />
            <Route
              exact
              path="/email-callback/"
              render={(props) => {
                return (
                  <EmailCallback
                    onLoginSuccess={this.handleLoginSuccess}
                    onLoginFailed={this.handleLineLoginFailed}
                  />
                );
              }}
            />
            <Route exact path="/cert-login">
              <CertLogin onLoginSuccess={this.handleLoginSuccess} />
            </Route>
            <Route exact path="/Login">
              <DoctorLogin
                controller={this.loginController}
                lineLoginManager={this.lineLoginManager}
                onLoginSuccess={this.handleLoginSuccess}
                onHaveApiToken={() => {
                  this.props.history.push("/Chat");
                }}
              />
            </Route>
            <Route exact path="/callback">
              <IsHealthCommon.LineCallBack
                loading={this.state.landingLoading}
                onLoginSuccess={this.handleLineLoginSuccess}
                onLoginFailed={this.handleLineLoginFailed}
              />
            </Route>
            <Route
              path="/"
              render={(props) => {
                if (this.state.apiToken) {
                  return (
                    <MainScreen
                      {...props}
                      ref={(ref) => (this.mainScreen = ref)}
                      getLeftMenuItem={this.getLeftMenuItem}
                      getDivisionMenuItem={this.getDivisionMenuItem}
                      getRightMenuItem={this.getRightMenuItem}
                      chatController={this.ChatController}
                      diagFormController={this.diagFormController}
                      patientPanelController={this.patientPanelController}
                      nurseNoteController={this.nurseNoteController}
                      progressNoteController={this.progressNoteController}
                      queueController={this.queueController}
                      classifyController={this.classifyController}
                      crdVitalSignController={this.crdVitalSignController}
                      apiToken={this.state.apiToken}
                      onGetDivision={this.loadMydivision}
                      division={this.state.division}
                      userId={this.state.userId}
                      fullname={this.state.fullname}
                      username={this.state.username}
                      finishedTriageLevel={this.state.finishedTriageLevel}
                      providerId={this.state.providerId}
                      dashboardController={this.dashboardController}
                      appointmentDashboardController={
                        this.appointmentDashboardController
                      }
                      onReconnectWs={this.reconnectWs}
                      onSetState={(state) => this.setState({ ...state })}
                      storage={this.controller.storage}
                    />
                  );
                } else {
                  return (
                    <IsHealthCommon.LandingPage
                      detail={
                        <>
                          <span className="header darkblue">Is</span>
                          <span className="header">Health Doctor</span>
                          <div>
                            แพลตฟอร์มสำหรับแพทย์ ที่ใช้ในการสื่อสาร
                            ประเมินและให้คำปรึกษาผู้ป่วยผ่านระบบออนไลน์
                          </div>
                          <br />
                          <div>
                            สามารถตรวจสอบข้อมูลคิวผู้ป่วยที่จำเป็นจะต้องปรึกษาแพทย์
                            และตรวจสอบผลการบันทึกแบบคัดกรองตนเองของผู้ป่วย
                            ข้อมูลโน๊ตการพยาบาล
                            และบันทึกโน๊ตสำหรับแพทย์เพิ่มเติม ผ่านระบบออนไลน์
                          </div>
                          <br />
                          <div>
                            <Button
                              content="คลิกเพื่อเริ่มต้นใช้งานระบบ"
                              size="huge"
                              className="whiteBasicButton"
                              onClick={() => this.props.history.push("/Login")}
                            />
                          </div>
                        </>
                      }
                    />
                  );
                }
              }}
            />
          </Switch>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(withRouter(MainDoctor));
