import base from "../config/base.json";

var _settings = null;
const getSettings = () => {
  if (_settings !== null) {
    return _settings
  }
  _settings = new Settings()
  console.log(_settings, "_settings")
  return _settings
}

class Settings {
  constructor(domain = window.location.hostname) {
    const favor = this.selectDomain(domain);
    // url of api server
    this.API_HOST = favor.API_HOST || window.location.origin;
    // websocket server host name
    this.WS_HOST = favor.WS_HOST || window.location.hostname;
    // static host for api such as TRIAGE_LEVEL_CLASSIFY
    this.STATIC_HOST = favor.STATIC_HOST || 'https://admin.ishealth.app';
    // host of web rtc server
    this.WEB_RTC = favor.WEB_RTC || 'https://webrtc.drjaysayhi.com';

    this.APP_NAME = favor.APP_NAME || null;

    this.USERNAME_PASSWORD_LOGIN = favor.USERNAME_PASSWORD_LOGIN === undefined ?
      true : favor.USERNAME_PASSWORD_LOGIN;

    // Enable accounts.thevc authentication options
    this.PENTA_ACC_FACEBOOK_LOGIN = favor.PENTA_ACC_FACEBOOK_LOGIN === undefined ?
      true : favor.PENTA_ACC_FACEBOOK_LOGIN;
    this.PENTA_ACC_LINE_LOGIN = favor.PENTA_ACC_LINE_LOGIN === undefined ?
      true : favor.PENTA_ACC_LINE_LOGIN;
    this.PENTA_ACC_EMAIL_LOGIN = favor.PENTA_ACC_EMAIL_LOGIN === undefined ?
      true : favor.PENTA_ACC_EMAIL_LOGIN;

    // Booking function
    this.BOOKING_FUNCTION = favor.BOOKING_FUNCTION === undefined ?
      true : favor.BOOKING_FUNCTION;

    // Dashboard
    this.DASHBOARD = favor.DASHBOARD === undefined ?
      true : favor.DASHBOARD;

    // Webpush
    this.WEB_PUSH_PUBLIC_KEY = favor.WEB_PUSH_PUBLIC_KEY ||
      "BDKyMQyvGoPQ5LvRPBmQGec1_geS441vKvkdYhaNM4WDfd0a403b0BTunKNBST_-mcaR5at3B8FYL-N4xo4x9a0";

    this.LATEST_ENCOUNTER_TOP = favor.LATEST_ENCOUNTER_TOP === undefined ?
      false : favor.LATEST_ENCOUNTER_TOP;

    this.HIDE_QUEUE_FILTER_DATE = favor.HIDE_QUEUE_FILTER_DATE === undefined ? false : favor.HIDE_QUEUE_FILTER_DATE;

    this.USE_PAYMENT_STATUS_LABEL = favor.USE_PAYMENT_STATUS_LABEL === undefined ? false : favor.USE_PAYMENT_STATUS_LABEL;

    this.OPEN_FORM_ENCOUNTER = favor.OPEN_FORM_ENCOUNTER === undefined ? true : favor.OPEN_FORM_ENCOUNTER

    this.HIDE_CALL_BUTTON = favor.HIDE_CALL_BUTTON === undefined ? false : favor.HIDE_CALL_BUTTON

    this.COMPANY = favor.COMPANY === undefined ? "" : favor.COMPANY

    this.PROJECT = favor.PROJECT === undefined ? "" : favor.PROJECT

    this.CLASSIFY_CHAT = favor.CLASSIFY_CHAT === undefined ?
      false : favor.CLASSIFY_CHAT;

    // ---------------------------------- for CNMI telemed ----------------------------------
    this.HIDE_QUEUE = favor.HIDE_QUEUE === undefined ? false : favor.HIDE_QUEUE

    this.HIDE_SIDEBAR = favor.HIDE_SIDEBAR === undefined ? false : favor.HIDE_SIDEBAR

    this.HIDE_DIVISION = favor.HIDE_DIVISION === undefined ? false : favor.HIDE_DIVISION

    this.HIDE_NOTE = favor.HIDE_NOTE === undefined ? false : favor.HIDE_NOTE

    this.HIDE_CLASSIFY = favor.HIDE_CLASSIFY === undefined ? false : favor.HIDE_CLASSIFY

    // ---------------------------------- for Classify Sub Menu ----------------------------------
    this.HIDE_CLASSIFY_SUBMENU_CHAT = favor.HIDE_CLASSIFY_SUBMENU_CHAT === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_CHAT;

    this.HIDE_CLASSIFY_SUBMENU_ASSESSMENT = favor.HIDE_CLASSIFY_SUBMENU_ASSESSMENT === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_ASSESSMENT;

    this.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE = favor.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE = favor.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN = favor.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN;

    this.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE = favor.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_PROFILE = favor.HIDE_CLASSIFY_SUBMENU_PROFILE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_PROFILE;

    this.HIDE_CLASSIFY_BUTTON_NEW_PATIENT = favor.HIDE_CLASSIFY_BUTTON_NEW_PATIENT === undefined ?
      false : favor.HIDE_CLASSIFY_BUTTON_NEW_PATIENT;

    this.CLASSIFY_CHAT_FORM_HOST = favor.CLASSIFY_CHAT_FORM_HOST || "https://ishealth.app";

    this.GCS_BUCKET_NAME = favor.GCS_BUCKET_NAME || "mybplus-waiting-list-staging";

    this.PDF_PRINT_DIAGFORM_ID = favor.PDF_PRINT_DIAGFORM_ID === undefined ?
      [] : favor.PDF_PRINT_DIAGFORM_ID;

    this.AIRFLOW_CHAT = favor.AIRFLOW_CHAT === undefined ?
      false : favor.AIRFLOW_CHAT;
    // console.log(`Settings for ${domain}`);
    // console.log(this);
  }

  selectDomain(domain) {
    if (domain in base) {
      return base[domain]
    } else if (!'default' in base) {
      throw `cannot find "${domain}" or "default" in settings`
    }
    return base.default
  }
}

// export default new Settings();
export default getSettings();